import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MAPIURLMaker from '../MAPIURLMaker';
import AddIcon from '@mui/icons-material/Add'; // For "+"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; // For arrows
import SearchIcon from '@mui/icons-material/Search'; // For search
import '../../styles/new.css';

const WordMorphology = ({ word }) => {
  const [inputWord, setInputWord] = useState(word || '');
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);

  const fetchAnalysis = async (searchWord) => {
    try {
      const fetchStr = MAPIURLMaker(`analyze?word=${searchWord}`);
      const response = await axios.get(fetchStr);

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return response.data;
    } catch (error) {
      console.error('Fetch error:', error);
      setError('An error occurred while fetching the data. Please try again later.');
      return null;
    }
  };

  const handleSearch = async () => {
    const data = await fetchAnalysis(inputWord);
    if (data) {
      setResults(data);
      setError(null);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setInputWord(event.target.value);
    }
  };

  useEffect(() => {
    if (word !== inputWord) {
      setInputWord(word);
    }
  }, [word]);

  useEffect(() => {
    if (inputWord) {
      handleSearch();
    }
  }, [inputWord]);

  return (
    <div className="flex-column main-column">
      <div className="title">Morphological Analysis</div>
      <fieldset className="fieldset">
        <div className="legend">Search for a word :</div>
        <div className="flex-row">
          <input
            id='txtWordSearch'
            className="edit-box margin-all"
            type='text'
            value={inputWord}
            onChange={(e) => setInputWord(e.target.value)}
            placeholder="Enter word for search !"
            onKeyDown={handleKeyDown}
          />
          <button
            className="btn-style-active flex-row"
            onClick={handleSearch}
          >
            <div className="margin-mini">Search</div>
            <SearchIcon fontSize="small" />
          </button>
        </div>
      </fieldset>
      {error && <div className="error-text">{error}</div>}
      {results && <Results analyses={results} />}
    </div>
  );
};

const MorphemeBox = ({ text, isTop }) => (
  <div className={`morpheme-box ${isTop ? 'top' : 'bottom'}`}>
    {text}
  </div>
);

const PlusSign = () => (
  <div className="plus-sign">
    <AddIcon fontSize="small" />
  </div>
);

const Arrow = () => (
  <div className="arrow">
    <ArrowDownwardIcon fontSize="small" />
  </div>
);

// MorphemesRow component to render morphemes
const MorphemesRow = ({ morphemes }) => (
  <div className="table-morph">
    <div className="table-morph-row">
      {morphemes.map((morpheme, index) => (
        <React.Fragment key={index}>
          <div className="table-morph-cell">
            <MorphemeBox text={morpheme} isTop />
          </div>
          {index < morphemes.length - 1 && (
            <div className="table-morph-cell">
              <PlusSign />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  </div>
);

// Updated DescriptionsRow component to render combined descriptions
const DescriptionsRow = ({ descriptions }) => (
  <div className="table-morph">
    <div className="table-morph-row">
      {descriptions.map((descriptionGroup, index) => (
        <React.Fragment key={index}>
          <div className="table-morph-cell">
            <MorphemeBox text={descriptionGroup.join(' / ')} /> {/* Combine descriptions with ' / ' */}
          </div>
          {index < descriptions.length - 1 && (
            <div className="table-morph-cell">
              <PlusSign />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  </div>
);

const ArrowsRow = ({ count }) => (
  <div className="table-morph">
    <div className="table-morph-row">
      {Array.from({ length: count }).map((_, index) => (
        <React.Fragment key={index}>
          <div className="table-morph-cell" id="arrow-cell">
            <Arrow />
          </div>
          {index < count - 1 && (
            <div className="table-morph-cell">
              <div className="plus-sign"></div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  </div>
);

const Results = ({ analyses }) => {
  if (!Array.isArray(analyses)) {
    return <div className="error-text">No analyses available.</div>;
  }

  return (
    <div className="results-box flex-row">
      {analyses.map((analysis, index) => {
        const morphemes = analysis.morphemes;
        const descriptions = analysis.descriptions.map(desc => desc.split(' / ')); // Adjust descriptions

        return (
          <div key={index}>
            <div className="table-morph">
              <MorphemesRow morphemes={morphemes} />
              <ArrowsRow count={morphemes.length} />
              <DescriptionsRow descriptions={descriptions} />
            </div>
            {index < analyses.length - 1 && <div className="divider"></div>}
          </div>
        );
      })}
    </div>   
  );
};

export default WordMorphology;
