import React, { useState, useEffect } from "react";
import axios from "axios";
import MAPIURLMaker from '../MAPIURLMaker';
import "../../styles/new.css";

function QuoteFct({ word }) {
    const [quoteData, setQuoteData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Reset states before fetching new data
        setQuoteData(null);
        setIsLoading(true);
        setError(null);

        const fetchData = async () => {
            try {
                const fetchStr = MAPIURLMaker(`quotes?word=${word}`);
                const response = await axios.get(fetchStr);
                //console.log("API Response:", response.data);

                if (response && response.data && response.data.quote) {
                    setQuoteData(response.data);
                } else {
                    setQuoteData(null); // Clear quoteData if no data is found
                }
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setError('No quotes found for this word');
                } else {
                    setError('An unexpected error occurred while fetching the quote. Please try again later.');
                }
                console.error("No quotes found:", err);
            } finally {
                setIsLoading(false);
            }
        };

        if (word) {
            fetchData();
        }
    }, [word]); // Ensure this effect runs when `word` changes
/*
    if (isLoading) {
        return <p>Loading quote...</p>;
    }

    if (!quoteData) {
        return <p>No quotes found for this word :(</p>;
    }
*/
    // Function to highlight the searched word in the quote
    const highlightWord = (text, word) => {
        if (!text || !word) return text;
        const parts = text.split(new RegExp(`(${word})`, "gi"));
        return parts.map((part, index) =>
            part.toLowerCase() === word.toLowerCase() ? (
                <span key={index} className="quote-highlight">
                    {part}
                </span>
            ) : (
                part
            )
        );
    };

    return (
        <div className="flex-column main-column">
            <h2 className="title">Quote for: {word}</h2>
            
            {isLoading && <p>Loading quote...</p>}
                {error && <p>{error} :(</p>}
                {!isLoading && !error && !quoteData && <p>No quotes found for this word :(</p>}
            
            {quoteData && (
                <div className="quote-container">
                    <p className="quote-text">{highlightWord(quoteData.quote, word)}</p>
                    <div className="thumbnail-container">
                        <img
                            src={quoteData.thumbnail || `No thumbnail available`}
                            alt={quoteData.bookTitle || `No title available`}
                            className="quote-thumbnail"
                        />
                        <div className="book-details">
                            <h3>{quoteData.bookTitle || `Title unavailable`}</h3>
                            <p><strong>Author:</strong> {quoteData.author || `Unknown`}</p>
                            <p><strong>Publication Year:</strong> {quoteData.publicationYear || `N/A`}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default QuoteFct;
