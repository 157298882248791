import React from "react";

/* This component displays a clickable item for search results.
 It adjusts the background color based on a calculated HSL value from the distance prop and
  allows clicking to trigger a search with the displayed word.  */
const SearchResultItem = (props) => { // Check if props are undefined, render "Nothing" if true
  if (typeof props === "undefined") {
    return <div>Nothing</div>;
  } else {
    const { word, distance, setSearchWord } = props; // Destructure props to get word, distance, and setSearchWord function
    let index100 = distance * 100; // Calculate index value based on distance
    let hslValue = `hsl(100, ${index100}%, 75%)`; // Create HSL color value based on index100
    return (
      <div
        fontSize="small"
        style={{
          margin: "0.1em",
          padding: "0.2em",
          borderRadius: "0.3em",
          color: "#2C3B64",
          backgroundColor: hslValue, // Set background color dynamically based on hslValue
          borderStyle: "solid",
          borderColor: "#317c63",
          borderWidth: "0.2em",
          cursor: "pointer",
        }}
        title={word} // Show word as title on hover
        onClick={() => setSearchWord(word)} // Call setSearchWord function with word onClick
      >
        {word} {/* Display the word */}
      </div>
    );
  }
};

export default SearchResultItem;
