import "../../styles/new.css";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Helmet } from 'react-helmet-async';
import axios from "axios"; // Import Axios
import ChapterBlockFct from "./ChapterBlockFct";
import Search from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import { LangContext } from "../Contexts/LangContext";
import { WordContext } from "../Contexts/WordContext";
import { UserContext } from "../Contexts/UserContext";
import ShowWordLikesFct from "../SearchResults/ShowWordLikesFct";
import WordMorphology from "../Analysis/WordMorphology";
import APIURLMaker from "../APIURLMaker";
import Swal from "sweetalert2";
import DidYouMean from "../SearchResults/DidYouMean";
import QuoteFct from "./QuoteFct";

// Helper function to generate dynamic meta tags
const generateMetaTags = (word) => {
  return {
    title: `${word} | Yakın Sözlük | Türkçe-İngilizce Sözlük ve Eş Anlamlı Kelimeler`,
    description: `${word} ile ilgili eş anlamlı kelimeler, Türkçe morfolojik analiz ve İngilizce çeviri burada.`,
    content: `thesaurus, ${word}, eş anlamlı kelime, dictionary, ${word} kelimesinin eş anlamlısı, Türkçe-İngilizce çeviri, sözlük`
  };
};

// Helper function to generate JSON-LD structured data
const generateStructuredData = (word, lang1Pockets) => {
  const definitions = lang1Pockets.meanings || []; // Assuming `meanings` is the array of definitions
  const synonyms = lang1Pockets.synonyms || []; // Assuming `synonyms` is the array of synonyms

  return {
    "@context": "https://schema.org",
    "@type": "DefinedTermSet",
    "name": `Yakın Sözlük - ${word}`,
    "url": `https://lamba.dev/search?query=${word}`,
    "terms": definitions.map((definition) => ({
      "@type": "DefinedTerm",
      "name": word,
      "description": definition,
      "alternateName": synonyms.join(', ')
    }))
  };
};


// Handle word search and save operations, fetch word-related data from an API, and display search results
function FullWordFct(props) {
  // console.log('Render FullWordFct');
  const { word, setWord } = useContext(WordContext); // Destructuring word and setWord from WordContext
  const { lang1Pockets, initLang1Pockets } = useContext(LangContext); // Destructuring lang1Pockets and initLang1Pockets from LangContext
  const { user } = useContext(UserContext); // Destructuring user from UserContext
  const inputRef = useRef(null); // used to focus on the textbox upon load immediately

  useEffect(() => {
    const fetchData = async () => { // Async function to fetch data
      try {
        const URL = APIURLMaker(`meaningLangs/findMeaningLangs`); // Creating API URL for fetching meaning
        const body = { word: `${word}`, lang: "EN" }; // Defining request body
        const response = await axios.post(URL, body); // Making a POST request using Axios
        initLang1Pockets(response.data); // Initializing lang1Pockets with fetched data
      } catch (error) {
        if (error.response.data.exception_type === "RECORD_NOT_FOUND") {
          initLang1Pockets({}); // Initializing lang1Pockets with an empty object if no record is found
          console.info(
            "Record not found for : " + error.response.data.request_detail,
          );
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    if (word) {
      fetchData(); // Fetch data if word is defined
      inputRef.current.focus(); // Focus on the input field

      let tmpTextField = document.getElementById("txtWordSearch");
      if (tmpTextField.value !== word) {
        tmpTextField.value = word; // Update input field value if it doesn't match the current word
      }
    }

    // Cleanup function (optional)
    return () => {
      // Cleanup logic here if needed
    };
  }, [word, initLang1Pockets]);

  // Function to handle the submit action
  function submit() {
    let tmpTextField = document.getElementById("txtWordSearch");
    setWord(tmpTextField.value); // Set the word with the value from input field
  }

  // Function to save lang1Pockets
  const saveLang1Pockets = async (data) => {
    try {
      const flattenedPockets = Object.values(data).flatMap((arr) => arr); // Flatten the data

      const fullData = {
        word: word,
        lang: "EN",
        owner: user.login_id,
        pockets: flattenedPockets, // Prepare the data to be saved
      };
      console.log("Sending : ", fullData);
      const URL = APIURLMaker("meaningLangs/saveMeaningLangs");
      const response = await axios.post(URL, fullData, {
        headers: {
          Authorization: `Bearer ${user.jwt_token}`,
        },
      });
      Swal.fire({
        title: "Saved ..",
        text: response.data.pockets.length + " pocket saved !",
        icon: "success",
        confirmButtonText: "OK",
      }); // Show success alert
      console.log("Response : ", response);
      console.log("Data sent successfully:", response.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      }); // Show error alert
      console.error("Error saving data:", error);
    }
  };

  const metaTags = generateMetaTags(word);
  const structuredData = generateStructuredData(word, lang1Pockets);

  return (
    <div>
      <Helmet>
        <title>{metaTags.title}</title>
        <meta name="description" content={metaTags.description} />
        <meta name="content" content={metaTags.content} />
        {/* Inject JSON-LD structured data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <fieldset className="fieldset">
        <div className="legend">Search for a word or phrase :</div>
        <div className="flex-row">
          <input
            id="txtWordSearch"
            ref={inputRef}
            className="edit-box margin-all"
            type="text"
            name="searchItem"
            placeholder="Enter word for search !"
            onKeyDown={(event) => {
              if (
                event.key === "Enter" &&
                event.target.id === "txtWordSearch"
              ) {
                submit(); // Submit on pressing Enter
              }
            }}
          />
          <button
            className="btn-style-active flex-row"
            onClick={() => {
              submit(); // Submit on clicking Search button
            }}
          >
            <div className="margin-mini">Search</div>
            <Search fontSize="small" /> {/* Search icon */}
          </button>
          <button
            style={{ marginLeft: "auto", marginRight: "20px" }}
            className="btn-style-active flex-row"
            onClick={() => {
              saveLang1Pockets(lang1Pockets); // Save data on clicking Save button
            }}
          >
            <div className="margin-mini">Save</div>
            <SaveIcon fontSize="small" /> {/* Save icon */}
          </button>
        </div>

        {word !== "" && (
          <DidYouMean  word={word} />
        )}

      </fieldset>

      {word !== "" && (
        <div>
          {/*<DidYouMean  word={word} />*/}
          <ShowWordLikesFct props={{ word, setWord }} />  {/* Render search results */}
          <ChapterBlockFct props={{ word: word }} /> {/* Render chapter block */}
          <WordMorphology  word={word} />
          <QuoteFct word={word} />
        </div>
      )}
    </div>
  );
}

export default FullWordFct;
