import "../../styles/new.css";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import MAPIURLMaker from "../MAPIURLMaker";
import Swal from "sweetalert2";
import { WordContext } from "../Contexts/WordContext";

function DidYouMean({ word }) {
    const [rootWords, setRootWords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { setWord } = useContext(WordContext); // Use the setWord function from WordContext

    useEffect(() => {
      const fetchRootWords = async () => {
        if (!word) return; // Exit if no word is provided
  
        setLoading(true); // Set loading to true while fetching data
        setError(null); // Reset error state before fetching new data
        try {
          const URL = MAPIURLMaker(`check?word=${word}`); // Create API URL
          const response = await axios.get(URL); // Make GET request using Axios
          setRootWords(response.data); // Set root words from response data
        } catch (error) {
          console.error("Error fetching root words:", error); // Log the error to console
  
          // Display a detailed error message using SweetAlert2
          /*Swal.fire({
            title: "Error!",
            text:
              error.response?.data?.message ||
              error.message ||
              "An unknown error occurred while fetching the data. Please check the console for more details.",
            icon: "error",
            confirmButtonText: "OK",
          });
          console.error("Error :", error);*/
  
          setError(error); // Set error state if there is an error
        } finally {
          setLoading(false); // Set loading to false after data fetching
        }
      };
  
      fetchRootWords(); // Fetch root words when component mounts or word changes
    }, [word]);
  
    if (loading) return null;
  
    if (error) return <div className="error-text">Unable to fetch suggestions at the moment. Please try again later.</div>;
  
    if (rootWords.length === 0) return null;
  
    // Render root words in boxes
    const retval = (
      <div className="flex-row">
        <div className="legend">Did you mean :
        <div className="flex-row">
          {rootWords.map((rootWord, index) => (
            <div 
            key={index} 
            className="btn-style-active" 
            id="root-word-box"
            onClick={() => setWord(rootWord.rootWord)} // Update word when a root word is clicked
            >
              {rootWord.rootWord}
            </div>
          ))}
        </div>
        </div>
       </div> 
      
    );
  
    return retval;
  }
export default DidYouMean;