import React, { useContext, useState } from "react";
import "../../styles/new.css";
import { Grid, Paper } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import FullWordFct from "./FullWordFct";
import { LangContext } from "../Contexts/LangContext";
import DragEndTypePocketOrder from "../DragFunctions/DragEndType1";
import DragEndTypeCopyLangOrChangePocket from "../DragFunctions/DragEndType2";
import DragEndTypeChangeOrderInPocket from "../DragFunctions/DragEndType3";
import Swal from "sweetalert2";
import { UserContext } from "../Contexts/UserContext";
import HistoryColumn from "../Columns/HistoryColumn";
import GoogleAdsColumn from "../Columns/GoogleAdsColumn";
import SupportingDataColumn from "../Columns/SupportingDataColumn";

// Define the main layout and functionality of the application, including drag and drop features and context management
function MainContainerFct(props) {
  const { user } = useContext(UserContext); // Get user context
  const { lang1Library, updateLang1Library, lang1Pockets, updateLang1Pockets } =
    useContext(LangContext); // Get language context

  // Function to handle drag and drop events
  const handleDragEnd = (result) => {
    if (!result.destination) return; // If there's no destination, return early
    const { source, destination } = result; // Destructure source and destination from result

    // Handling different types of drag and drop scenarios based on droppableIds
    if (
      source.droppableId === "English" &&
      destination.droppableId === "English"
    ) {
      DragEndTypePocketOrder(result, lang1Library, updateLang1Library); // Call function for reordering pockets within the same language
    } else if (source.droppableId !== destination.droppableId) {
      try {
        DragEndTypeCopyLangOrChangePocket(
          result,
          lang1Library,
          lang1Pockets,
          updateLang1Pockets,
          "English",
          user,
        ); // Call function for copying language or changing pocket
      } catch (e) {
        console.error(e);
        Swal.fire({
          title: "Error!",
          text: e.message,
          icon: "warning",
          confirmButtonText: "OK",
        }); // Display error message using Swal if an error occurs
      }
    } else {
      DragEndTypeChangeOrderInPocket(result, lang1Pockets, updateLang1Pockets); // Call function for reordering items within the same pocket
    }
  };

  return (
    <Grid container spacing={0}> {/* Use MUI Grid instead of custom flexbox */}
    
      <DragDropContext onDragEnd={handleDragEnd}> {/* DragDropContext component to manage drag and drop */}
      <Grid item xs={12} sm={6} md={6} lg={2} order={{ xs: 3, sm: 3, md: 3, lg: 1 }}> {/* Container with flex layout */}
        <div className="history-column"> {/* Div for history column */}
          <HistoryColumn /> {/* Rendering HistoryColumn component */}
        </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} order={{ xs: 1, sm: 1, md: 1, lg: 2 }}> 
          <Paper className="main-column paper"> {/* Paper component for styling */}
            <div className="title">Search Word</div> {/* Title for the search section */}
            <FullWordFct /> {/* Rendering FullWordFct component for searching words */}
          </Paper>
        </Grid>
        

        <Grid item xs={12} sm={6} md={6} lg={2} order={{ xs: 2, sm: 2, md: 2, lg: 3 }}> {/* Supporting Column */}
        <div className="supporting-column"> {/* Div for supporting data column */}
          <SupportingDataColumn /> {/* Rendering SupportingDataColumn component */}
        </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={2} order={{ xs: 4, sm: 4, md: 4, lg: 4 }}> {/* Google Ads Column */}
        <div className="google-ads-column"> {/* Div for Google Ads column */}
          <GoogleAdsColumn /> {/* Rendering GoogleAdsColumn component */}
        </div>
        </Grid>
      </DragDropContext>
    
    </Grid>
  );
}

export default MainContainerFct;
